import React from "react";
import logo from "../images/logo.svg";

const Drama = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto px-4 py-8">
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4">Conflict Resolution</h2>
          <p className="text-lg mb-8">
            Master a powerful framework that will help you navigate conflicts
            with confidence, improve relationships, and create more positive
            outcomes in both personal and professional situations. These tools
            can transform challenging interactions into opportunities for growth
            and understanding.
          </p>

          <div className="grid md:grid-cols-2 gap-4">
            {/* Drama Triangle */}
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-red-600 mb-10">
                The Drama Triangle
              </h3>
              <div className="relative w-full" style={{ minHeight: "260px" }}>
                <div className="absolute w-full flex items-center justify-center">
                  <div className="w-0 h-0 border-l-[150px] border-r-[150px] border-b-[240px] border-l-transparent border-r-transparent border-b-red-200 relative rounded-[8px]">
                    <div className="absolute top-14 left-1/2 -translate-x-1/2 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Persecutor</p>
                      <p className="text-xs">("I'm right!")</p>
                    </div>
                    <div className="absolute top-48 -left-28 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Victim</p>
                      <p className="text-xs">("Poor me")</p>
                    </div>
                    <div className="absolute top-48 -right-28 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Rescuer</p>
                      <p className="text-xs">("Let me help")</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Empowerment Triangle */}
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-green-600 mb-10">
                The Empowerment Triangle
              </h3>
              <div className="relative w-full" style={{ minHeight: "260px" }}>
                <div className="absolute w-full flex items-center justify-center">
                  <div className="w-0 h-0 border-l-[150px] border-r-[150px] border-b-[240px] border-l-transparent border-r-transparent border-b-green-200 relative rounded-[8px]">
                    <div className="absolute top-14 left-1/2 -translate-x-1/2 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Challenger</p>
                      <p className="text-xs">("Let's grow")</p>
                    </div>
                    <div className="absolute top-48 -left-28 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Creator</p>
                      <p className="text-xs">("I choose")</p>
                    </div>
                    <div className="absolute top-48 -right-28 text-center whitespace-nowrap">
                      <p className="font-bold text-sm">Coach</p>
                      <p className="text-xs">("What's possible?")</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">How can I help?</h2>
          <p className="text-lg mb-4">
            I'm Gerrit Giliomee, and I offer pro-bono coaching sessions on
            Fridays to help individuals and teams recognize their patterns in
            the Drama Triangle and guide them toward the empowering alternatives
            of the Empowerment Triangle. All sessions are ideally conducted in a
            neutral space to ensure comfort and confidentiality.
          </p>
          <ul className="list-disc list-inside space-y-2 mb-6">
            <li>Team workshops and training</li>
            <li>Conflict resolution facilitation</li>
          </ul>
          <div className="text-center mb-10">
            <a
              href="https://wa.me/27724860718"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 bg-green-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-600 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z" />
              </svg>
              Contact me on WhatsApp
            </a>
          </div>
          <h2 className="text-2xl font-semibold mb-4">Why am I doing this?</h2>
          <p className="text-lg mb-4">
            In short, with all the conflict and problems South Africans faces,
            it seems like the right thing to put some energy into.
          </p>
        </section>
      </main>
    </div>
  );
};

export default Drama;
